// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-empresa-js": () => import("./../../../src/pages/a-empresa.js" /* webpackChunkName: "component---src-pages-a-empresa-js" */),
  "component---src-pages-fale-conosco-js": () => import("./../../../src/pages/fale-conosco.js" /* webpackChunkName: "component---src-pages-fale-conosco-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nossos-clientes-js": () => import("./../../../src/pages/nossos-clientes.js" /* webpackChunkName: "component---src-pages-nossos-clientes-js" */),
  "component---src-pages-nossos-servicos-js": () => import("./../../../src/pages/nossos-servicos.js" /* webpackChunkName: "component---src-pages-nossos-servicos-js" */),
  "component---src-pages-politica-de-privacidade-js": () => import("./../../../src/pages/politica-de-privacidade.js" /* webpackChunkName: "component---src-pages-politica-de-privacidade-js" */),
  "component---src-pages-termos-e-condicoes-js": () => import("./../../../src/pages/termos-e-condicoes.js" /* webpackChunkName: "component---src-pages-termos-e-condicoes-js" */)
}

