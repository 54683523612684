import React, { memo } from 'react';

import { Link } from 'gatsby';
import { useStaticQuery, graphql } from "gatsby";

import { useLocation } from '@reach/router';




/* const FaleConoscoIcon = ({name, children}) =>
{
    const data = useStaticQuery(graphql`
        query {
            iconendereco: file(relativePath: { eq: "icon-endereco.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            icontelefone: file(relativePath: { eq: "icon-telefone.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            iconemail: file(relativePath: { eq: "icon-email.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            mapmarker: file(relativePath: { eq: "mapmarker.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            whatsapp: file(relativePath: { eq: "whatsapp.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    if ( name === 'icon-endereco.png' ) {
        return <img draggable="false" src={data.iconendereco.childImageSharp.fluid.src} alt="" width="33" height="47" />
    } else if ( name === 'icon-telefone.png' ) {
        return <img draggable="false" src={data.icontelefone.childImageSharp.fluid.src} alt="" width="47" height="47" />
    } else if ( name === 'icon-email.png' ) {
        return <img draggable="false" src={data.iconemail.childImageSharp.fluid.src} alt="" width="42" height="48" />
    } else if ( name === 'whatsapp.png' ) {
        return <img draggable="false" src={data.whatsapp.childImageSharp.fluid.src} style={{display: 'block'}}  alt="Whatsapp" title="Whatsapp" className="whatsapp__logo" width="55" height="55" />
    }
}

 */

const Picture = ({name}) =>
{
    const data = useStaticQuery(graphql`
        query {
            image1: file(relativePath: { eq: "logo__footer.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            image2: file(relativePath: { eq: "social__facebook.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            image3: file(relativePath: { eq: "social__facebook__hover.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            image4: file(relativePath: { eq: "social__instagram.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            image5: file(relativePath: { eq: "social__instagram__hover.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            image6: file(relativePath: { eq: "social__linkedin.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            image7: file(relativePath: { eq: "social__linkedin__hover.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    if ( name === 'logo__footer.png' ) {
        return <img draggable="false" width="204" height="76" src={data.image1.childImageSharp.fluid.src} alt="A & R Inteligência Fiscal e Tributária" title="A & R Inteligência Fiscal e Tributária" />
    } else if ( name === "social__facebook.png" ) {
        return <img draggable="false" src={data.image2.childImageSharp.fluid.src} alt="Facebook" className="icon" width="10" height="17" />
    } else if ( name === "social__facebook__hover.png" ) {
        return <img draggable="false" src={data.image3.childImageSharp.fluid.src} alt="Facebook" className="icon__hover" width="10" height="17" />
    } else if ( name === "social__instagram.png" ) {
        return <img draggable="false" src={data.image4.childImageSharp.fluid.src} alt="Instagram" className="icon" width="17" height="16" />
    } else if ( name === "social__instagram__hover.png" ) {
        return <img draggable="false" src={data.image5.childImageSharp.fluid.src} alt="Instagram" className="icon__hover" width="17" height="16" />
    } else if ( name === "social__linkedin.png" ) {
        return <img draggable="false" src={data.image6.childImageSharp.fluid.src} alt="LinkedIn" className="icon" width="17" height="16" />
    } else if ( name === "social__linkedin__hover.png" ) {
        return <img draggable="false" src={data.image7.childImageSharp.fluid.src} alt="LinkedIn" className="icon__hover" width="17" height="16" />
    }
}



const TempRodape = (props) =>
{
    /* useEffect(() => {
        console.log('Rodape:','render - ',pathname);
        return () => {
            console.log('Rodape:','unmount');
        };
    }, []); */

    const { pathname } = useLocation();

    return (
        <footer>
            <div className="container">
                <div className="footervc">
                    <div className="footer__normal">
                        <div className="footer__normal1" data-aos="fade-in" data-aos-duration="1000">
                            <Link tabIndex="0" to="/" className="logo__footer">
                                <Picture name="logo__footer.png" />
                            </Link>
                            <div className="footer__links__group">
                                <div className="footer__links footer__link__space">
                                    <Link tabIndex="0" className={['/nossos-servicos', '/nossos-servicos/'].indexOf(pathname) !== -1 ? 'footer__link active' : 'footer__link'} to="/nossos-servicos/"><span className="footer__link__bullet">&#9642;&nbsp;&nbsp;&nbsp;</span>Serviços</Link>
                                    <Link tabIndex="0" className={['/fale-conosco', '/fale-conosco/'].indexOf(pathname) !== -1 ? 'footer__link active' : 'footer__link'} to="/fale-conosco/"><span className="footer__link__bullet">&#9642;&nbsp;&nbsp;&nbsp;</span>Contato</Link>
                                    <Link tabIndex="0" className={['/nossos-clientes', '/nossos-clientes/'].indexOf(pathname) !== -1 ? 'footer__link active' : 'footer__link'} to="/nossos-clientes/"><span className="footer__link__bullet">&#9642;&nbsp;&nbsp;&nbsp;</span>Parceiros</Link>
                                </div>
                                {/* <div className="footer__links footer__link__space">
                                    <Link className={['/nossos-servicos', '/nossos-servicos/'].indexOf(pathname) !== -1 ? 'footer__link active' : 'footer__link'} to="/nossos-servicos/"><span className="footer__link__bullet">&#9642;&nbsp;&nbsp;&nbsp;</span>Serviços</Link>
                                    <Link className="footer__link" to="/#clientes"><span className="footer__link__bullet">&#9642;&nbsp;&nbsp;&nbsp;</span>Parceiros</Link>
                                </div>
                                <div className="footer__links">
                                    <Link className={['/fale-conosco', '/fale-conosco/'].indexOf(pathname) !== -1 ? 'footer__link active' : 'footer__link'} to="/fale-conosco/"><span className="footer__link__bullet">&#9642;&nbsp;&nbsp;&nbsp;</span>Contato</Link>
                                </div> */}
                            </div>
                        </div>
                        <div className="footer__normal2" data-aos="fade-in" data-aos-duration="1000">
                            <div className="footer__title"><div className="footer__title__text">Contate-nos</div></div>
                            <div className="footer__border">
                                <span>Rua Francisco Braga, 335 – sala 03</span>
                                <span>16015-560 – Araçatuba / SP</span>
                                <span>Tel.:&nbsp;<a tabIndex="0" href="tel:+551832162987">(18) 3216-2987</a> / WA:&nbsp;<a tabIndex="0" rel="nofollow noreferrer" href="https://wa.me/5518991210260" target="_blank" title="Whatsapp">(18) 99121-0260</a></span>
                                <span>E-mail:&nbsp;<a tabIndex="0" href="mailto:comercial@arinteligenciatributaria.com.br">comercial@arinteligenciatributaria.com.br</a></span>
                            </div>
                            <div className="footer__social">
                                <div className="footer__title"><div className="footer__title__text">Siga-nos</div></div>
                                <div className="socialicons">
                                    <a tabIndex="0" rel="nofollow" href="https://www.facebook.com/arinteligenciafiscal/?view_public_for=101034091555600" title="Facebook" className="btn__facebook">
                                        <Picture name="social__facebook.png" />
                                        <Picture name="social__facebook__hover.png" />
                                    </a>
                                    <a tabIndex="0" rel="nofollow" href="https://www.instagram.com/arinteligenciatributaria/?hl=pt-br" title="Instagram" className="btn__instagram">
                                        <Picture name="social__instagram.png" />
                                        <Picture name="social__instagram__hover.png" />
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="footer__normal3" data-aos="fade-in" data-aos-duration="1000">
                            <div className="footer__title"><div className="footer__title__text">Siga-nos</div></div>
                            <div className="socialicons">
                                <a tabIndex="0" rel="nofollow" href="https://www.facebook.com/arinteligenciafiscal/?view_public_for=101034091555600" title="Facebook" className="btn__facebook">
                                    <Picture name="social__facebook.png" />
                                    <Picture name="social__facebook__hover.png" />
                                </a>
                                <a tabIndex="0" rel="nofollow" href="https://www.instagram.com/arinteligenciatributaria/?hl=pt-br" title="Instagram" className="btn__instagram">
                                    <Picture name="social__instagram.png" />
                                    <Picture name="social__instagram__hover.png" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="footer__bottom">
                        <div className="footer__bottom1">Copyright &copy; 2020 • <a rel="nofollow" href="https://www.agencianeoplan.com.br">Neoplan</a>.</div>
                        <div className="footer__bottom2">
                            <Link tabIndex="0" to="/a-empresa/" className={['/a-empresa', '/a-empresa/'].indexOf(pathname) !== -1 ? 'active' : ''}>Sobre</Link>
                            <Link tabIndex="0" to="/fale-conosco/" className={['/fale-conosco', '/fale-conosco/'].indexOf(pathname) !== -1 ? 'active' : ''}>Contate-nos</Link>
                            <Link tabIndex="0" to="/termos-e-condicoes/" className={['/termos-e-condicoes', '/termos-e-condicoes/'].indexOf(pathname) !== -1 ? 'active' : ''}>Termos e condições</Link>
                            <Link tabIndex="0" to="/politica-de-privacidade/" className={['/politica-de-privacidade', '/politica-de-privacidade/'].indexOf(pathname) !== -1 ? 'active' : ''}>Política de privacidade</Link>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

const Rodape = memo(TempRodape);

export default Rodape;