import React, { Component } from 'react';

import { Link } from 'gatsby';
import { useStaticQuery, graphql } from "gatsby";

import { globalHistory } from '@reach/router';

import { Helmet } from 'react-helmet';

import Img from 'gatsby-image';




const Picture = ({name}) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
            title
        }
      }
      placeholderImage: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 138, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      whatsapp: file(relativePath: { eq: "whatsapp.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  if ( name === "logo.png" ) {
    return <Img fixed={data.placeholderImage.childImageSharp.fixed} alt={data.site.siteMetadata.title} title={data.site.siteMetadata.title} />
  } else if ( name === 'whatsapp.png' ) {
    return <img draggable="false" src={data.whatsapp.childImageSharp.fluid.src} style={{display: 'block'}}  alt="Whatsapp" title="Whatsapp" className="whatsapp__logo" width="55" height="55" />
  }
}


class Cabecalho extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            showMenu: false,
            showGototop: false,
            pathname: ''
        }

        this.openMobile = this.openMobile.bind(this);
        this.closeMobile = this.closeMobile.bind(this);
        this.goToTop = this.goToTop.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    openMobile()
    {
        this.setState({
            showMenu: true
        }/* , () => {
            //console.log('openMobile:', this.state.showMenu);
        } */);
    }

    closeMobile()
    {
        this.setState({
            showMenu: false
        }/* , () => {
            //console.log('closeMobile:', this.state.showMenu);
        } */);
    }

    goToTop()
    {
        if ( typeof document !== `undefined` && typeof window !== `undefined` )
        {
            window.scrollTo(0, 0); // Chrome 40.0.2214.109 ( Tested in LG JOY H222tTV - Android 4.4 )

            (
              document.documentElement ||
              document.body.parentNode ||
              document.body
            ).scrollTop = 0;

            /* if (document.documentElement) {
                document.documentElement.scrollTop = 0;
            } else {
                document.body.scrollTop = 0;
            } */
        }
    }

    handleScroll(e)
    {
        ////console.log('handleScroll:',e);

        if ( typeof document !== `undefined`)
        {
            const topValue = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;

            this.setState({
                showGototop: topValue > 181 ? true : false
            }/* , () => {
                //console.log('showGototop:',this.state.showGototop);
            } */);
        }
    }

    shouldComponentUpdate(nextProps, nextState)
    {
        return (
            nextState.showGototop !== this.state.showGototop || nextState.showMenu !== this.state.showMenu || nextState.pathname !== this.state.pathname
        );
    }

    render()
    {
        //console.log('Cabecalho:','render');

        return (
            <>
                <header>
                    <div className="header__top" />
                    <div className="header">
                        <div className="container">
                            <div className="logo">
                                <Link to="/" className="logo__header" tabIndex="0">
                                    <Picture name="logo.png" />
                                </Link>
                            </div>
                            <ul className="menu">
                                <li><Link tabIndex="0" to="/a-empresa/" className={['/a-empresa', '/a-empresa/'].indexOf(this.state.pathname) !== -1 ? 'active' : ''}>A EMPRESA</Link></li>
                                <li><Link tabIndex="0" to="/nossos-servicos/" className={['/nossos-servicos', '/nossos-servicos/'].indexOf(this.state.pathname) !== -1 ? 'active' : ''}>NOSSOS SERVIÇOS</Link></li>
                                <li><Link tabIndex="0" to="/nossos-clientes/">NOSSOS CLIENTES</Link></li>
                                <li><Link tabIndex="0" to="/fale-conosco/" className={['/fale-conosco', '/fale-conosco/'].indexOf(this.state.pathname) !== -1 ? 'active' : ''}>FALE CONOSCO</Link></li>
                            </ul>
                            <div className="menu__mobile">
                                <button aria-label="Menu mobile" className="ion navicon ion-45" onClick={this.openMobile} />
                            </div>
                        </div>
                    </div>
                </header>

                <div className={this.state.showMenu === true ? 'modalmobile showmobile' : 'modalmobile'} onClick={this.closeMobile} role="presentation">
                    <ul>
                        <li><Link tabIndex="0" to="/">INÍCIO</Link></li>
                        <li><Link tabIndex="0" to="/a-empresa/">A EMPRESA</Link></li>
                        <li><Link tabIndex="0" to="/nossos-servicos/">NOSSOS SERVIÇOS</Link></li>
                        <li><Link tabIndex="0" to="/nossos-clientes/">NOSSOS CLIENTES</Link></li>
                        <li><Link tabIndex="0" to="/fale-conosco/">FALE CONOSCO</Link></li>
                    </ul>
                </div>

                <button tabIndex="0" aria-label="Ir para o topo" className={this.state.showGototop === true ? 'button__up ion chevron-up scrolled' : 'button__up ion chevron-up'} onClick={this.goToTop} title="Clique aqui para subir"></button>

                <a tabIndex="0" rel="nofollow noreferrer" href="https://wa.me/5518991210260" target="_blank" className="button__whatsapp" title="Whatsapp">
                    <Picture name="whatsapp.png" />
                    <div className="whatsapp__text">WhatsApp</div>
                </a>

                <Helmet>
                    <link type="text/plain" rel="author" href="https://www.arinteligenciatributaria.com.br/humans.txt" />
                    <script type="text/javascript">
                        {`var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
                        (function(){
                            var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
                            s1.async=true;
                            s1.src='https://embed.tawk.to/60352ddb9c4f165d47c653a7/1ev7rm902';
                            s1.charset='UTF-8';
                            s1.setAttribute('crossorigin','*');
                            s0.parentNode.insertBefore(s1,s0);
                        })();`}
                    </script>
                    <body className={this.state.showMenu === true ? 'showmobile' : ''} />
                </Helmet>
            </>
        )
    }

    componentDidMount()
    {
        //console.log('Cabecalho:','componentDidMount');

        if ( typeof window !== `undefined` )
        {
            window.addEventListener('scroll', this.handleScroll);
        }

        const spathname = globalHistory.location.pathname;
        //console.log('Cabecalho[componentDidMount]:',spathname);

        this.setState({
            pathname: spathname
        }, () => {
            //console.log('location[pathname]:',this.state.pathname);
        });

        globalHistory.listen(({ action, location: { pathname } }) =>
        {
            //console.log('location[pathname]:',this.state.pathname);

            if ( action === "PUSH" )
            {
                this.setState({
                    pathname
                }/* , () => {
                    //console.log('location[pathname]:',this.state.pathname);
                } */);
            }
        })
    }

    componentWillUnmount()
    {
        //console.log('Cabecalho:','componentWillUnmount');

        if ( typeof window !== `undefined` )
        {
            window.removeEventListener('scroll', this.handleScroll);
        }
    }
}

export default Cabecalho;