module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#b67b21","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"A & R Inteligência Fiscal e Tributária","short_name":"A & R","start_url":"https://www.arinteligenciatributaria.com.br","background_color":"#b67b21","theme_color":"#b67b21","display":"minimal-ui","icon":"src/images/aer_icon.png","icons":[{"src":"icons/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"icons/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"icons/icon-180x180.png","sizes":"180x180","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"05b4114168873cfcb18be19d2b72793b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-171187670-1","head":false,"defer":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
