/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from "react"

import Layout from "./src/components/layout/layout"

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}

/* export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `Esta aplicação foi atualizada. ` + `Recarregar para exibir a versão mais recente?`
  )
  if (answer === true) {
    window.location.reload()
  }
} */

/* export const registerServiceWorker = () => true */