/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, memo } from 'react';

import Cabecalho from './cabecalho';
import Rodape from './rodape';

import '../css/style.css';




const TempLayout = ({children}) =>
{
    useEffect(() =>
    {
        if (typeof window !== `undefined`) {
            window.sessionStorage.removeItem('banners');
        }

        //console.log('Layout:','render');
        return () => {
            //console.log('Layout:','unmount');
        };
    }, []);

    //console.log('Layout:',children.props.location.pathname);

    return (
        <>
            <Cabecalho key={'siteheader'} location={children.props.location.pathname} />
            <main key={'sitebody'}>{children}</main>
            <Rodape key={'sitefooter'} />
        </>
    )
}

const Layout = memo(TempLayout);

export default Layout;